<template>
  <v-card-actions
    class="pa-6 pb-5">
    <v-spacer />
    <v-btn
      v-if="cancelText"
      outlined
      color="lightBlue"
      @click="onCancelClick">
      {{ cancelText }}
    </v-btn>
    <v-btn
      v-if="submitText"
      color="lightBlue"
      @click="onSubmitClick">
      {{ submitText }}
    </v-btn>
  </v-card-actions>
</template>
<script>
export default {
  name: 'ProjectDetailControlsViewActions',
  props: {
    cancelText: {
      type: String,
      default: '',
    },
    submitText: {
      type: String,
      default: '',
    },
  },
  methods: {
    onCancelClick() {
      this.$emit('onCancelClick');
    },
    onSubmitClick() {
      this.$emit('onSubmitClick');
    },
  },
};
</script>
